// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$admin-portal-primary: mat.define-palette(mat.$indigo-palette);
$admin-portal-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$admin-portal-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$admin-portal-theme: mat.define-light-theme((color: (primary: $admin-portal-primary,
        accent: $admin-portal-accent,
        warn: $admin-portal-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($admin-portal-theme);

/* You can add global styles to this file, and also import other style files */
// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import styles with default layout.
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";

// Some temp fixes
@import "fixes";

// Prism.js
@import "examples";

// If you want to add something do it here
@import "custom";

@import 'ag-grid-community/styles/ag-grid.css';
/* Quartz Theme Specific CSS */
@import 'ag-grid-community/styles/ag-theme-quartz.css';

// @import '~ngx-toastr/toastr.css';

.ag-header-cell-label {
  cursor: none;
}

.ag-header-row {
  background-color: #f0f3f4;
  color: #f0f3f4;
}

.ag-theme-alpine {
  --ag-cell-horizontal-border: var(--ag-row-border-width) var(--ag-row-border-style) var(--ag-row-border-color);
  /* Add left and right borders to each cell and use row border's properties  */
  --ag-header-column-separator-display: block;
}

.ag-header-cell-label {
  color: black;
  font-weight: 600;
}

.grid-card-body {
  // margin-right: 16%;
  width: 97%;
  position: relative;
}


.ag-theme-alpine .ag-rtl .ag-pinned-left-header .ag-header-row::before,
.ag-theme-alpine .ag-ltr .ag-pinned-right-header .ag-header-row::after {
  content: "";
  position: absolute;
  height: calc(100% - 20px);
  top: 10px;
  width: 0px;
  background-color: #babfc7;
  background-color: var(--ag-border-color, #babfc7);
}

.sidebar-brand {
  background-color: white !important;
}

.sidebar-nav {
  background-color: #052a36;
  margin-left: -10px;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
}


.custom-dialog-overlay {
  filter: blur(20px);
  background-color: #aba1a1;
  opacity: 0.7 !important;
}


// ::ng-deep .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
//   color: var(--green, #80c431);
// }

// ::ng-deep .mat-mdc-checkbox, .mat-accent, .mat-mdc-checkbox-checked{
//   color: var(--green, #80c431);
// }

.mat-mdc-checkbox {  
  --mdc-checkbox-selected-hover-icon-color: #80c431;
  .mdc-checkbox {
    .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
      border-color: var(--green, #80c431) !important;
      background-color: var(--green, #80c431) !important;
      // color: var(--green, #80c431);
    }
  }
}

// ::ng-deep .mat-mdc-checkbox.mat-accent {
//   color: var(--green, #80c431);
// }

.mat-mdc-radio-checked {
  color: var(--green, #80c431);

}

.mat-accent {
  --mat-option-selected-state-label-text-color: #80c431;
}

.mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-hover-icon-color: #80c431;
  --mdc-radio-selected-icon-color: #80c431;
  --mdc-radio-selected-pressed-icon-color: #80c431;
  --mat-radio-checked-ripple-color: #80c431;
}

.mat-radio-outer-circle {
  .mat-mdc-radio-checked & {
    border-color: #80c431;
  }
}

.mat-radio-inner-circle {
  background-color: #80c431;
}

.mat-radio-ripple .mat-ripple-element {
  background-color: rgba(#80c431, 0.26);
}

.ng-select .ng-select-container:after {
  border-bottom: 0px;
  content: "";
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  transition: border-color 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
}

.ng-select .ng-select-container {
  border: 1px solid #e4e7ea;
  border-bottom: 1px solid #e4e7ea;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  position: absolute;
  color: rgba(0, 0, 0, 0.54);
  padding-left: 10px;
  transform-origin: left 0;
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.ng-dropdown-panel.ng-select-bottom {
  top: 100%;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-color: 1px solid #e6e6e6;
  margin-top: 4px;
  border: 1px solid;
  background-color: white;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 8px;
}

.ng-select {
  padding-bottom: 0px;
}

.input-group>.form-control,
.input-group>.form-control-plaintext,
.input-group>.custom-select,
.input-group>.custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 70%;
  margin-bottom: 0;
  margin-right: 1px solid #e4e7ea;
}

// .campaignSelect > .cdk-overlay-pane {
//   left: 643px !important;
// }

::ng-deep .campaignSelect {
  .cdk-overlay-pane {
    left: 643px !important;
  }
}

input {
  border: 0;
}

.asterix:after {
  content: "*";
  color: red;
  font-size: 14px;
}

.mat-mdc-form-field>.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: none !important;
  padding: 0;
}

#toast-container>div {
  opacity: 1;
}

.toast {
  font-size: initial !important;
  border: initial !important;
  backdrop-filter: blur(0) !important;
}

.toast-success {
  background-color: #51A351 !important;
}

.toast-error {
  background-color: #BD362F !important;
}

.toast-info {
  background-color: #2F96B4 !important;
}

.toast-warning {
  background-color: #F89406 !important;
}


*:focus {
  outline: none;
}

.form-control {
  border: none !important;
  box-shadow: none;

  :focus {
    outline: none;
    border: none !important;
  }
}

[type="search"]::-webkit-search-cancel-button {
  appearance: none;
}
::-ms-reveal {
  display: none;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}
.mdc-line-ripple {
  display: none;
}

input[type="checkbox"]:checked  {
  fill: #51A351;
background-color: #51A351;
}

::ng-deep .mat-mdc-form-field-subscript-wrapper{
  margin-bottom: -1.25em;
}

::ng-deep .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
  max-width: 300px !important;
  margin: 1px !important;
}

.cdk-overlay-container {
 z-index: 1055;
}
.body{
  font-weight: 500 !important;
}